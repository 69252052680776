<!--
 * @Author: your name
 * @Date: 2021-07-31 13:28:31
 * @LastEditTime: 2023-04-03 20:35:59
 * @LastEditors: yao_wb
 * @Description: In User Settings Edit
 * @FilePath: \fl-pc\src\views\BranchDetais.vue
-->
<template>
  <div class="page">
    <div class="banner">
      <img src="@/assets/free-course/activebanner.png" />
    </div>
    <div class="course-introduce">
      <img class="course-img" :src="couseInfo.poster" alt="" />
      <div class="introduce-info">
        <div class="introduce-title">
          <span>{{ couseInfo.name }}</span>
          <img src="@/assets/free-course/fh.png" />
        </div>
        <div class="introduce-content" v-if="couseInfo && couseInfo.aboutUs">
          <!-- {{ couseInfo.sketch }} -->
          <div>{{ couseInfo.aboutUs.split("\n")[0] }}</div>
          <div>
            {{
              couseInfo.aboutUs.split("\n")[1].split("\t")[0] +
              couseInfo.aboutUs.split("\n")[1].split("\t")[1]
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="tabs">
      <div class="tab-box1">
        <div
          :class="[tabActive == index ? 'active-tab' : '', 'tabs-item']"
          v-for="(item, index) in tabList"
          :key="index"
          @click="setTabActive(index)"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div class="main-content">
      <div class="teacher-title">{{ tabList[tabActive] }}</div>
      <div v-if="tabActive === 0">{{ couseInfo.sketch }}</div>
      <div v-if="tabActive === 3" v-html="couseInfo.introduce"></div>
      <Active
        :activeList="list"
        :show="false"
        :isOther="true"
        v-if="(tabActive === 1 || tabActive === 2) && list.length > 0"
        :type="tabActive + 1"
      />
      <Pagination
        v-show="(tabActive === 1 || tabActive === 2) && query.total > 0"
        :total="query.total"
        :page="query.page"
        :limit="query.limit"
        @pagination="pagination"
      />
      <NoData
        v-if="(tabActive === 1 || tabActive === 2) && list.length <= 0"
        imgType="course"
        hint="暂无活动"
      />
    </div>
  </div>
</template>

<script>
import { getBranchId } from "@/api/home.js";
import Active from "@/components/home/active.vue";
import Pagination from "@/components/pagination.vue";
import NoData from "@/components/no-data.vue";
import { getActiveList, getClassList } from "@/api/home.js";
import { BreadcrumbItem } from "element-ui";
export default {
  components: { Active, Pagination, NoData },
  computed: {},
  data() {
    return {
      couseInfo: {},
      dialogVisible: false,
      dialogVisible1: false,
      tabList: ["简介", "活动", "兴趣班", "服务范围"],
      tabActive: 0,
      list: [],
      query: {
        page: 1,
        limit: 10,
        total: 0,
      },
    };
  },
  created() {
    this.query.branchId = this.$route.query.id;
  },
  mounted() {
    this.getInfo();
  },
  destroyed() {},
  methods: {
    getInfo() {
      getBranchId(this.$route.query.id).then((res) => {
        if (res.code == 0) {
          this.couseInfo = res.data;
        }
      });
      this.getList();
    },
    getList() {
      this.query.branchId = this.$route.query.id;
      const query = this.query;
      if (this.tabActive === 1) {
        getActiveList(query).then((res) => {
          if (res.code === 0) {
            this.list = res.data.list;
            this.query.total = res.data.total;
          }
        });
      } else {
        getClassList(query).then((res) => {
          if (res.code === 0) {
            this.list = res.data.list;
            this.query.total = res.data.total;
          }
        });
      }
    },
    setTabActive(index) {
      this.tabActive = index;
      this.query = {
        page: 1,
        limit: 10,
        total: 0,
      };
      this.getList();
    },
    pagination(page) {
      this.query.page = page.page;
      this.query.limit = page.limit;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.page {
	background-color: #f8f8f8;
	width: 100%;
	min-height: 100vh;
	padding-bottom: 30px;
}
.main-content {
	width: 1200px;
	margin: 0px auto 0;
	text-align: left;
    background: #ffffff;
    padding:20px
	// display: flex;
}
.banner {
	width: 100%;
	height: 250px;
	img {
		width: 100%;
		height: 100%;
	}
}
.course-introduce {
	width: 1200px;
	margin: 0px auto;
	height: 200px;
	background: #ffffff;
	border-radius: 8px;
	padding: 20px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	transform: translateY(-125px);
	position: relative;
	.course-img {
		display: inline-block;
		width: 213px;
		height: 160px;
		margin-right: 20px;
	}
	.introduce-info {
		text-align: left;
		display: flex;
		flex-direction: column;
		// justify-content: space-between;
		height: 100%;
		flex: 1;
		padding: 5px 0 0;
		.introduce-title {
			font-size: 20px;
			font-family: Microsoft YaHei;
			font-weight: 600;
			color: #333333;
            display: flex;
            align-items: center;
            img{
                width: 67px;
                height: 20px;
                margin-left: 11px;
            }
		}
		.introduce-content {
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #666666;
			height: 60px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
			margin-top: 30px;
		}
	}
}

.tabs {
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 1920px;
	min-width: 1200px;
	margin: 0 auto;
	width: 100%;
	height: 60px;
	margin-top: -100px;
	.tab-box1 {
		display: flex;
		align-items: center;
		justify-content: center;
		transform: translateY(-15px);
	}
	.tabs-item {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-flow: column;
		width: 180px;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
	}
	.active-tab {
		color: #fb3a4e;
		position: relative;
		&::after {
			content: ' ';
			display: inline-block;
			position: absolute;
			top: 30px;
			width: 20px;
			height: 2px;
			background: #fb3a4e;
			border-radius: 2px;
		}
	}
}

.teacher-title {
	display: flex;
	align-items: center;
	font-size: 18px;
	font-family: Source Han Sans CN-Bold, Source Han Sans CN;
	font-weight: 500;
	color: #333333;
	margin-bottom: 25px;
	  &::before{
	      content: ' ';
	      display: inline-block;
	      width: 4px;
	      height: 16px;
	      background: #FB3A4E;
	      margin-right: 10px;
	  }
}
</style>
